import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import Select, { components } from 'react-select'
import { ChevronDown } from '~components/Svg'

const DropdownIndicator = (props) => {
	return (
		<components.DropdownIndicator {...props}>
			<ChevronDown label="Emoji" css={css`width: 16px; max-height: 60px;`}/>
		</components.DropdownIndicator>
	)
}

const SelectDropDown = ({ className, options, placeholder, outline, onChange }) => {
	const [active, setActive] = useState(false)
	const [complete, setComplete] = useState(false)
	const selectRef = useRef()

	const returnActive = () => {
		setComplete(false)
		setActive(true)
	}

	const returnState = () => {
		setActive(true)
		setComplete(true)
	}

	const selectStyles = {

		control: (styles, {isFocused}) => { 
			return {
				...styles, 
				borderRadius: '0px',
				height: '60px',
				backgroundColor: 
					outline ? 'var(--beige60)' : 'var(--white)'
				,
				display: 'flex',
				boxShadow: 'none',
				minWidth: '300px',
				border: outline ? 
					isFocused 
						? '2px solid var(--bteal)' 
						: '2px solid var(--dteal)'
					: 'none',
				borderBottom: 
					outline ? 
						isFocused 
							? '2px solid var(--bteal)' 
							: '2px solid var(--dteal)':
						isFocused 
							? '2px solid var(--bteal)' 
							: '2px solid var(--white)',
				isFocused: {
					opacity: 1,
				},
				':hover': {
					...styles[':hover'],
					boxShadow: 'none',
					borderColor: isFocused 
						? 'var(--bteal)' 
						:	'var(--dteal)',
					borderBottom: outline ? 
						isFocused 
							? '2px solid var(--bteal)' 
							: '2px solid var(--dteal)':
						isFocused 
							? '2px solid var(--bteal)' 
							: '2px solid var(--white)',
				},
			}	
		},
		option: (styles, { isDisabled, isFocused, isSelected }) => {
			return {
				...styles,
				marginTop: '0px',
				minHeight: '36px',
				display: 'flex',
				alignItems: 'center',
				padding: '3px var(--xs)',
				':first-of-type': {
					...styles[':first-of-type'],
					marginTop: '0px',
				},
				backgroundColor: isDisabled
					? undefined
					: isSelected
						? 'var(--bteal40)'
						: isFocused
							? 'var(--bteal40)'
							: 'var(--bteal20)',
				color: isDisabled
					? '#ccc'
					: isSelected
						? 'black'
						: 'black',
				cursor: isDisabled 
					? 'not-allowed' 
					: 'default',
				':active': {
					...styles[':active'],
					backgroundColor: !isDisabled
						? isSelected
							? 'white'
							: 'var(--bteal40)'
						: undefined,
				},
			}
		},
		input: (styles) => ({ 
			...styles,
		}),
		placeholder: (styles) => ({ 
			...styles,
			color: 'var(--dteal)'
		}),
		singleValue: (styles) => ({ ...styles }),
		menu: (styles) => ({
			...styles,
			marginTop: '0px',
			marginBottom: '0px',
			paddingTop: '0px',
			boxShadow: 'none',
			backgroundColor: 'var(--lteal)',
			borderRadius: '0px',
			filter: 'drop-shadow(0px 4px 0px rgba(215, 210, 206, 0.25))',
			width: '100%',
			minWidth: '300px',
			zIndex: '3',
		}),
		menuList: (styles) => ({
			...styles,
			paddingTop: '0px',
			paddingBottom: '0px',
			width: '100%'
		}),
		indicatorSeparator: (styles) => ({ 
			...styles,
			display: 'none',
		}),
		valueContainer: (styles) => ({ 
			...styles,
			padding: '2px var(--xs)',
		}),
		dropdownIndicator: (styles) => ({
			...styles,
			color: 'var(--dteal)',
			padding: '0 var(--xs)',
			':hover': {
				...styles[':hover'],
				color: 'var(--dteal)',
			},
		}),
	} 

	return (
		<Wrap className='select-wrap'>
			{outline ? 
				<OutlineCaption css={css`
					display: ${(active || complete) ? 'block' : 'none'};
					bottom: ${ active ? '100%' : 'calc(100% + var(--xxxs))'};
					transform: ${ active ? 'translateX(13px) translateY(50%)' : 'none'};
				`}>
					<CaptionText 
						className='cap'
						css={css`
							background-color: var(--beige60);
							color: ${ active ? 'var(--bteal)' : 'var(--dteal60)'}; 
						`}
					>
						{placeholder}
					</CaptionText>
				</OutlineCaption>	
				:
				<Caption css={css`
					display: ${complete ? 'block' : 'none'};
					bottom: calc(100% + var(--xxxs));
				`}>
					<CaptionText 
						className='cap'
						css={css`
						color: ${ active ? 'var(--bteal)' : 'var(--dteal60)'}; 
					`}
					>
						{placeholder}
					</CaptionText>
				</Caption>
			}
			<Select 
				ref={selectRef}
				css={css``}
				className={`${className} h5`}
				styles={selectStyles}
				options={options}
				onBlur={()=> returnState()}
				blurInputOnSelect
				onFocus={()=> returnActive()}
				placeholder={placeholder}
				components={{ DropdownIndicator }}
				onChange={onChange}
			/>
		</Wrap>
	)
}

const Wrap = styled.div`
	display: inline-block;
	position: relative;
`
const Caption = styled.div`
	position: absolute;
	z-index: 2;
`

const OutlineCaption = styled(Caption)`
`

const CaptionText = styled.label`
	padding: 2px 4px;
	display: inline-block;
`

SelectDropDown.propTypes = {
	className: PropTypes.string,
	options: PropTypes.array,
	placeholder: PropTypes.string,
	outline: PropTypes.bool,
	onChange: PropTypes.func,
}

export default SelectDropDown